import './App.css';
import Form from './Form';

function App() {
  return (
    <div className="App">
      <center>
      <Form/>
      </center>
    </div>
  );
}

export default App;
